const Breakpoints = {
  mobile: 320,
  mobileWider: 425,
  tablet: 768,
  laptop: 1024,
  largeLaptop: 1440,
  fhd: 1920,
};

export default Breakpoints;
